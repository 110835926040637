import React, { Component } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { Section, MarkdownContent } from "../../components/Section"
import { TwoColumns } from "../../components/Grid"
import SectionButton from "../../components/Button/SectionButton"

const HeroTitle = styled.h1`
  font-size: 54px;
  line-height: 64px;
  color: white;
  margin: 0;
  text-align: left;
  @media (max-width: 768px) {
    text-align: center;
    margin-top: 100px;
  }
`
const HeroDes = styled.div`
  font-size: 18px;
  line-height: 30px;

  text-align: left;
  margin-top: 9px;
  & p {
    color: white;
    text-align: left;
  }
  @media (max-width: 768px) {
    text-align: center;
  }
`
const BackgroundImage = styled.div`
  background: url(//images.ctfassets.net/z75g1qwq4el7/1aEQQP4amjqrsmVdUPmD3K/879ffd1d8b4272dbd20be49376500d86/cta-background.png)
    top center;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  opacity: 0.1;
`
const RightSide = styled.div`
  text-align: right;
  @media (max-width: 768px) {
    text-align: center;
    margin-top: 40px;
  }
`

class AboutHero extends Component {
  render() {
    return (
      <Section pt="280px" pb="209px" bg="#1E4156">
        <div className="container">
          <TwoColumns>
            <div>
              <HeroTitle>{this.props.data.title}</HeroTitle>
              <HeroDes>
                <MarkdownContent
                  textAlign="center"
                  dangerouslySetInnerHTML={{
                    __html: this.props.data.description.childMarkdownRemark
                      .html,
                  }}
                />
              </HeroDes>
            </div>
            <RightSide>
              <Link to="/products/">
                <SectionButton
                  text="Explore More Buildings"
                  width="100%"
                  maxWidth="350px"
                />
              </Link>
            </RightSide>
          </TwoColumns>
        </div>
        <BackgroundImage />
      </Section>
    )
  }
}

export default AboutHero
